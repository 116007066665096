import React from 'react';
import {Link} from 'gatsby';

import {
    Layout,
    Nav,
} from 'components';

const NotFound = () => {
    return (
        <Layout>
            <Nav/>
            <header>
                <h1>{'Whoops'}</h1>
                <h2>{'This page does not exist.'}</h2>
                <h2>
                    <Link to="/">
                        {'Go Home'}
                    </Link>
                </h2>
            </header>
        </Layout>
    );
};

export default NotFound;
